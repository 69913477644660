<template>
  <div class="container">
    <!-- 头部 -->
    <div class="heard">
      <screendeilHead
        :title="title"
        :projName="projName"
        :totalTime="totalTime"
        :deviceOnline="deviceOnline"
        :deviceNo="deviceNo"
        :workData="workData"
        :showWarm="showWarm"
        :statusOnline="statusOnline"
        :versioncc="versioncc"
        :deviceType="deviceType"
        :realTimeData="realTimeData"
        @rests="subscribeWorkSet"
        :svgTimes="svgTimes"
        :selfDevice="selfDevice"
      ></screendeilHead>
    </div>
    <!-- 主体 -->
    <div class="main">
      <div class="leftMain">
        <div class="leftTop">
          <div class="realBox">
            <load style="width: 100%" :realTimeData="realTimeData"></load>
            <MomentratioDel
              v-if="deviceType == 0"
              style="width: 100%"
              :realTimeData="realTimeData"
            ></MomentratioDel>
            <Slide
              style="width: 100%"
              :realTimeData="realTimeData"
              v-if="deviceType != 2"
            ></Slide>
            <RotaTionDel
              v-if="deviceType == 0"
              style="width: 100%"
              :realTimeData="realTimeData"
            ></RotaTionDel>
            <HeightTionDel
              style="width: 100%"
              :realTimeData="realTimeData"
            ></HeightTionDel>
            <LuffingDel
              v-if="deviceType == 0"
              style="width: 100%"
              :realTimeData="realTimeData"
            ></LuffingDel>
            <WindDel style="width: 100%" :realTimeData="realTimeData"></WindDel>
            <Horizontal
              v-if="deviceType != 2"
              style="width: 100%"
              :realTimeData="realTimeData"
            ></Horizontal>
            <traliveDel
              v-if="deviceType == 2"
              style="width: 100%"
              :realTimeData="realTimeData"
            ></traliveDel>
          </div>
        </div>
        <div class="leftModel">
          <div class="leftdel">
            <div class="henxian">
              <div class="box8">
                <div class="box9">
                  <div>
                    <img
                      src="../../../assets/images/screen/sanjiaoxin.png"
                      alt=""
                    />
                  </div>
                  <div class="title">数据模拟</div>
                </div>
                <div class="box10">
                  <img
                    src="../../../assets/images/screen/duobianx.png"
                    alt=""
                  />
                </div>
                <div
                  class="title"
                  style="margin-right: 10px; cursor: pointer"
                  @click="svgClick"
                  v-if="deviceType == 0"
                >
                  更多
                </div>
              </div>
              <div class="henxianbox"></div>
            </div>

            <div class="simulatedBox">
              <simulated
                :title="title"
                :deviceType="deviceType"
                :realTimeData="realTimeData"
                :workData="workData"
                :svgTimes="svgTimes"
              ></simulated>
            </div>
            <div class="warnBoxsc">
              <div class="singular" v-for="(item, index) in list" :key="index">
                <div
                  class="showBox"
                  :class="[item.show == true ? 'showBox2' : 'showBox']"
                >
                  <div class="test">
                    <div v-if="item.show == false">
                      <img
                        src="../../../assets/images/screen/newnormal.png"
                        alt=""
                      />
                    </div>
                    <div v-if="item.show == true">
                      <img
                        src="../../../assets/images/screen/newpolice.png"
                        alt=""
                      />
                    </div>
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="leftdel2 video" ref="videoRef">
            <div class="henxian">
              <div class="box8">
                <div class="box9">
                  <div>
                    <img
                      src="../../../assets/images/screen/sanjiaoxin.png"
                      alt=""
                    />
                  </div>
                  <div class="title">监控视频</div>
                </div>
                <div class="box10">
                  <img
                    src="../../../assets/images/screen/duobianx.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="henxianbox"></div>
            </div>
            <div class="tabs">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                  :label="item.cameType"
                  :name="item.cameSn"
                  v-for="(item, index) in videoList"
                  :key="index"
                >
                </el-tab-pane>
              </el-tabs>
            </div>

            <div class="videoss">
              <ScreenVideo
                :data="videoInfo"
                :height="videoHeight"
                :width="videoWidth"
                :index="2"
                class=""
              ></ScreenVideo>
            </div>
          </div>
        </div>
        <div class="leftBottom">
          <div class="leftBottomdel">
            <div class="henxian">
              <div class="box8">
                <div class="box9">
                  <div>
                    <img
                      src="../../../assets/images/screen/sanjiaoxin.png"
                      alt=""
                    />
                  </div>
                  <div class="title">实时数据</div>
                </div>
                <div class="box10">
                  <img
                    src="../../../assets/images/screen/duobianx.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="henxianbox"></div>
            </div>
            <div class="realDatadetailbox">
              <realDatadetail
                v-if="deviceType == 0"
                :title="title"
                :realTimeData="realTimeData"
                :times="times"
              ></realDatadetail>
              <LiftRealDatadetail
                v-if="deviceType == 1"
                :title="title"
                :realTimeData="realTimeData"
                :times="times"
              ></LiftRealDatadetail>
              <bridgeRealDatadetail
                v-if="deviceType == 3"
                :title="title"
                :realTimeData="realTimeData"
                :times="times"
              ></bridgeRealDatadetail>
              <doorRealDatadetail
                v-if="deviceType == 2"
                :title="title"
                :realTimeData="realTimeData"
                :times="times"
              ></doorRealDatadetail>
            </div>
          </div>
          <div class="leftBottomdel2">
            <div class="henxian">
              <div class="box8">
                <div class="box9">
                  <div>
                    <img
                      src="../../../assets/images/screen/sanjiaoxin.png"
                      alt=""
                    />
                  </div>
                  <div class="title">工况数据</div>
                </div>
                <div class="box10">
                  <img
                    src="../../../assets/images/screen/duobianx.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="henxianbox"></div>
            </div>
            <div class="workSetData">
              <workSetData
                :workData="workData"
                v-if="deviceType == 0"
              ></workSetData>
              <LiftWorkSetData
                :workData="workData"
                v-if="deviceType == 1"
              ></LiftWorkSetData>
              <bridgeWorkSetData
                :workData="workData"
                v-if="deviceType == 3"
              ></bridgeWorkSetData>
              <doorWorkSetData
                :workData="workData"
                v-if="deviceType == 2"
              ></doorWorkSetData>
            </div>
          </div>
        </div>
      </div>
      <div class="rightMain">
        <div class="rightTop">
          <userInfo :projId="projId"   :title="title"></userInfo>
        </div>
        <div class="rightBottom" ref="rightBottomref">
          <div class="henxian">
            <div class="box8">
              <div class="box9">
                <div>
                  <img
                    src="../../../assets/images/screen/sanjiaoxin.png"
                    alt=""
                  />
                </div>
                <div class="title">今日告警</div>
              </div>
              <div class="box10">
                <img src="../../../assets/images/screen/duobianx.png" alt="" />
              </div>
            </div>
            <div class="henxianbox"></div>
          </div>
          <div class="warndetail">
            <detailToDayWarn
              :title="title"
              :tableHeight="tableHeight"
            ></detailToDayWarn>
          </div>
        </div>
      </div>
    </div>
    <!-- <svgDialog
      :visible.sync="showDialog"
      @close="colseDialog"
      @done="doneDialog"
      :realTimeData="realTimeData"
      :workData="workData"
      v-if="deviceType == 0"
      :svgTimes="svgTimes"
    ></svgDialog> -->
  </div>
</template>

<script>
// import mqtt from "mqtt";
import screendeilHead from "../../../components/screen/fiveEqument/screendeilHead.vue";
import load from "../../../components/screen/fiveEqument/load.vue";
import MomentratioDel from "../../../components/screen/fiveEqument/MomentratioDel.vue";
import realDatadetail from "../../../components/screen/fiveEqument/realDatadetail.vue";
import LiftRealDatadetail from "../../../components/screen/fiveEqument/LiftRealDatadetail.vue";
import bridgeRealDatadetail from "../../../components/screen/fiveEqument/bridgeRealDatadetail.vue";
import doorRealDatadetail from "../../../components/screen/fiveEqument/doorRealDatadetail.vue";
import simulated from "../../../components/screen/fiveEqument/simulated.vue";
import ScreenVideo from "../../../components/screen/fiveEqument/ScreenVideo.vue";
import userInfo from "../../../components/screen/fiveEqument/userInfo.vue";
import workSetData from "../../../components/screen/fiveEqument/workSetData.vue";
import LiftWorkSetData from "../../../components/screen/fiveEqument/LiftWorkSetData.vue";
import doorWorkSetData from "../../../components/screen/fiveEqument/doorWorkSetData.vue";
import bridgeWorkSetData from "../../../components/screen/fiveEqument/bridgeWorkSetData.vue";
import detailToDayWarn from "../../../components/screen/fiveEqument/detailToDayWarn.vue";
import Slide from "../../../components/screen/fiveEqument/SlideDel.vue";
import RotaTionDel from "../../../components/screen/fiveEqument/RotaTionDel.vue";
import HeightTionDel from "../../../components/screen/fiveEqument/HeightTionDel.vue";
import LuffingDel from "../../../components/screen/fiveEqument/LuffingDel.vue";
import WindDel from "../../../components/screen/fiveEqument/WindDel.vue";
import Horizontal from "../../../components/screen/fiveEqument/HorizontalDel.vue";
import svgDialog from "../../../components/screen/fiveEqument/svgDialog.vue";
import screen from "../../../api/modules/screen";
import traliveDel from "../../../components/screen/fiveEqument/traliveDel.vue";
import { createConnect, endConnect, subscribeTopic } from "../../../api/mqtt";
import { createConnects, endConnects } from "../../../api/mqtt2";
export default {
  components: {
    screendeilHead,
    load,
    MomentratioDel,
    realDatadetail,
    simulated,
    ScreenVideo,
    userInfo,
    workSetData,
    detailToDayWarn,
    Slide,
    RotaTionDel,
    HeightTionDel,
    LuffingDel,
    WindDel,
    Horizontal,
    LiftWorkSetData,
    LiftRealDatadetail,
    bridgeRealDatadetail,
    bridgeWorkSetData,
    svgDialog,
    doorRealDatadetail,
    traliveDel,
    doorWorkSetData,
  },
  data() {
    return {
      showDialog: false,
      timer: null,
      timer2: null,
      showWarm: false,
      // list: [
      //   { name: "重量", id: 1, show: false, type: "weight" },
      //   { name: "力矩", id: 2, show: false, type: "moment" },
      //   { name: "高度", id: 3, show: false, type: "height" },
      //   { name: "变幅", id: 4, show: false, type: "amplitude" },
      //   { name: "旋转", id: 5, show: false, type: "rotate" },
      //   { name: "仰角", id: 6, show: false, type: "elevation" },
      //   { name: "风速", id: 7, show: false, type: "wind" },
      //   { name: "行走", id: 8, show: false, type: "walk" },
      //   { name: "障碍物", id: 9, show: false, type: "barrier" },
      //   { name: "防碰撞", id: 10, show: false, type: "anticollision" },
      // ],
      list: [
        { name: "左禁行区", id: 11, show: false, type: "anticollision" },
        { name: "远禁行区", id: 12, show: false, type: "anticollision" },
        { name: "高限位", id: 12, show: false, type: "anticollision" },
        { name: "远限位", id: 12, show: false, type: "anticollision" },
        { name: "左限位", id: 12, show: false, type: "anticollision" },
        { name: "倾斜", id: 12, show: false, type: "anticollision" },
        { name: "风速", id: 12, show: false, type: "anticollision" },
        { name: "左碰撞", id: 12, show: false, type: "anticollision" },
        { name: "右碰撞", id: 12, show: false, type: "anticollision" },
        { name: "右禁行区", id: 12, show: false, type: "anticollision" },
        { name: "近禁行区", id: 12, show: false, type: "anticollision" },
        { name: "低限位", id: 12, show: false, type: "anticollision" },
        { name: "近限位", id: 12, show: false, type: "anticollision" },
        { name: "右限位", id: 12, show: false, type: "anticollision" },
        { name: "载重", id: 12, show: false, type: "anticollision" },
        { name: "低碰撞", id: 12, show: false, type: "anticollision" },
        { name: "右碰撞", id: 12, show: false, type: "anticollision" },
        { name: "近碰撞", id: 12, show: false, type: "anticollision" },
      ],
      value: "",
      projId: "",
      title: "",
      deviceType: "",
      videoInfo: {
        type: 0,
        url: "", //E60695771
      },
      videoHeight: 0,
      videoWidth: 0,
      activeName: "",
      videoList: [],
      projId: "",
      realTimeData: {},
      times: "",
      workData: {},
      videoType: "",
      deviceOnline: "",
      deviceId: "",
      deviceNo: "",
      versioncc: "",
      statusOnline: "",
      totalTime: "",
      tableHeight: 0,
      svgTimes: [],
      selfDevice: {},
      projName:''
    };
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.timer);
    // clearInterval(this.timer2);
    endConnect();
    endConnects();
  },
  mounted() {
    const query = this.$route.query;
    console.log("query", query);
    this.typeDel = {
      title: query.title,
      deviceType: query.deviceType,
    };
    this.deviceId = query.deviceId;
    this.projId = query.projId;
    this.deviceType = query.deviceType;
    this.title = query.title;
    this.deviceOnline = query.deviceOnline;
    this.showWarm = Boolean(query.showWarm);
    this.projName = query.projName
    this.videoType = query.type;
    this.deviceNo = query.deviceNo;
    this.getVideoList(this.title, query.type);
    // this.getRealDataList(this.title);
    this.tableHeight = this.$refs.rightBottomref.clientHeight - 50;
    this.getSelectDeviceAlarm(this.title);
    this.timer = setInterval(() => {
      this.subscribeVicinityStatus();
    }, 2000);

    // this.videoInfo = {
    //   type: query.type,
    //   url: query.url,
    // };
    this.videoHeight = this.$refs.videoRef.clientHeight + 40;
    this.videoWidth = this.$refs.videoRef.clientWidth;
    console.log(this.videoHeight, this.videoWidth);
    this.test();

    // this.getStatus()
    this.subscribeWorkSet();
    this.subscribeVicinityAttribute();
  },
  methods: {
    //订阅实时数据
    test() {
      let that = this;
      if (this.deviceType == 0) {
        createConnect(
          [`F/+/+/+/+/+/+/+/+/${this.title}/Data`],
          function (topic, message) {
            let realTimeData = {};
            let newDataObject = {};
            let newTravelObject = {};
            let newRotationObject = {};

            const processObject = (originalObject, targetObject) => {
              for (let key in originalObject) {
                let newKey = key.replace("@", "seep");
                targetObject[newKey] = originalObject[key];
              }
            };

            processObject(message.tower[0].gantry[0].hoist[0], newDataObject);
            processObject(message.tower[0].gantry[0], newTravelObject);
            processObject(message.tower[0], newRotationObject);

            Object.assign(realTimeData, {
              load: message.tower[0].gantry[0].hoist[0]?.weight || 0,
              momentratio: message.tower[0].gantry[0]?.momentratio || 0,
              slide: message.tower[0].gantry[0].hoist[0]?.slide || 0,
              rotation: message.tower[0]?.rotation || 0,
              height: message.tower[0].gantry[0].hoist[0]?.height || 0,
              luffing: message.tower[0].gantry[0]?.travel || 0,
              wind: message.generic.wind || 0,
              horizontal: message.tower[0]?.horizontal || 0,
              travel: message.tower[0].gantry[0]?.travel || 0,
              weightAnalog: newDataObject.weightseepanalog || 0,
              heightAnalog: newDataObject.heightseepencoder || 0,
              travelAnalog: newTravelObject.travelseepencoder || 0,
              rotationAnalog: newRotationObject.rotationseepencoder || 0,
            });

            that.realTimeData = realTimeData;
          
            
            that.times = message.timestamp;
            that.totalTime = message.workcycle[0]?.totaltime;
          }
        );
      } else if (this.deviceType == 1) {
        createConnect(
          `F/+/+/+/+/+/+/+/+/${this.title}/Data`,
          function (topic, message) {
            let realTimeData = {};
            let newWeightObject = {};
            let newHeighttionObject = {};
          
            const processObject = (originalObject, targetObject) => {
              for (let key in originalObject) {
                let newKey = key.replace("@", "seep");
                targetObject[newKey] = originalObject[key];
              }
            };
            if (message) {
              let doorFront = "";
              let doorRoof = "";
              message.generic.limiter?.map((item) => {
                if (item.type == "door_front") {
                  doorFront = item.triggered;
                } else if (item.type == "door_roof") {
                  doorRoof = item.triggered;
                }
              });
             
                  processObject(message.hoist[0], newWeightObject);
                  processObject(message.hoist[0], newHeighttionObject);
              Object.assign(realTimeData, {
                load: message.hoist[0].weight || 0,
                momentratio: 0,
                slide: message.hoist[0]?.slide || 0,
                rotation: 0,
                height: message.hoist[0].height || 0,
                luffing: 0,
                wind: message.generic.wind || 0,
                horizontal: message.hoist[0].horizontal || 0,
                floor: message.hoist[0]?.floor || 0,
                personcount: message.hoist[0]?.personcount || 0,
                doorFront: doorFront,
                doorRoof: doorRoof,
                speed: message.hoist[0].speed || 0,
                travel: 0,
                weightAnalog: newWeightObject.weightseepanalog,
                    heightAnalog: newHeighttionObject.heightseepencoder,
                travelAnalog: 0,
                rotationAnalog: 0,
              });
            } else {
              Object.assign(realTimeData, {
                load: 0,
                momentratio: 0,
                slide: 0,
                rotation: 0,
                height: 0,
                luffing: 0,
                wind: 0,
                horizontal: 0,
                floor: 0,
                personcount: 0,
                doorFront: 0,
                doorRoof: 0,
                speed: 0,
                travel: 0,
                weightAnalog: 0,
                heightAnalog: 0,
                travelAnalog: 0,
                rotationAnalog: 0,
              });
            }
            that.realTimeData = realTimeData;

            that.times = message.timestamp;
            that.totalTime = message.workcycle[0]?.totaltime;
          }
        );
      } else if (this.deviceType == 3) {
        createConnect(
          `F/+/+/+/+/+/+/+/+/${this.title}/Data`,
          function (topic, message) {
            let newDataObject = {};
            let newTravelObject = {};
            let newWeightObject = {};
            let newHeighttionObject = {};
            const processObject = (originalObject, targetObject) => {
              for (let key in originalObject) {
                let newKey = key.replace("@", "seep");
                targetObject[newKey] = originalObject[key];
              }
            };

            let realTimeData = {};
            let realTimeData2 = {};
            if (message) {
              message.gantry?.map((item) => {
                if (item.label == "front") {
                  processObject(item, newTravelObject);
                  processObject(item.hoist[0], newWeightObject);
                  processObject(item.hoist[0], newHeighttionObject);
                  Object.assign(realTimeData, {
                    load: item.hoist[0].weight.toFixed(1) || 0,
                    height: item.hoist[0].height.toFixed(1) || 0,
                    // wind: message.generic.wind || 0,
                    travel: item.travel.toFixed(1),
                    wind: message.generic?.wind || 0,
                    travelAnalog: newTravelObject.travelseepencoder || 0,
                    weightAnalog: newWeightObject.weightseepanalog,
                    heightAnalog: newHeighttionObject.heightseepencoder,
                  });
                } else if (item.label == "rear") {
                  Object.assign(realTimeData, {
                    load2: item.hoist[0]?.weight.toFixed(1) || 0,
                    height2: item.hoist[0]?.height.toFixed(1) || 0,
                    // wind: message.generic.wind || 0,
                    wind: message.generic?.wind || 0,
                    travel2: item.travel.toFixed(1),
                  });
                }
              });
            }
            that.realTimeData = realTimeData;

            that.times = message.timestamp;
            that.totalTime = message.workcycle[0]?.totaltime;
          }
        );
      } else if (this.deviceType == 2) {
        createConnect(
          `F/+/+/+/+/+/+/+/+/${this.title}/Data`,
          function (topic, message) {
            let realTimeData = {};
            let newWeightObject = {}
            let newHeighttionObject = {};
            let  newTraversetionObject = {};
            let  newTraversetionObjectfront = {};
            let newTraversetionObjectrear = {}
            if (message) {
             
              const processObject = (originalObject, targetObject) => {
              for (let key in originalObject) {
                let newKey = key.replace("@", "seep");
                targetObject[newKey] = originalObject[key];
              }
            };
              message.gantry[0]?.trolley[0]?.hoist.map((item) => {
                if (item.label == "main") {
                  processObject(item, newWeightObject);
                  processObject(item, newHeighttionObject);
                 processObject(message.gantry[0].trolley[0], newTraversetionObject);
                 processObject(message.gantry[0].trolley[0], newTraversetionObjectfront);
                 processObject(message.gantry[0].trolley[0], newTraversetionObjectrear);
                
                  Object.assign(realTimeData, {
                    load: item.weight || 0,
                    height: item.height || 0,
                    // wind: message.generic.wind || 0,
                    travel: message.gantry[0]?.travel || 0,
                    wind: message.generic?.wind || 0,
                    speed: message.gantry[0]?.speed || 0,
                    speed2: message.gantry[0]?.trolley[0]?.speed || 0,
                    speed3: item.speed || 0,
                    weightAnalog:newWeightObject.weightseepanalog,
                    heightAnalog:newHeighttionObject.heightseepencoder,
                    traversetAnalog:newTraversetionObject.traverseseepencoder,
                    traversetAnalogfront:newTraversetionObject.traverseseepladar_front,
                    traversetAnalogrear:newTraversetionObject.traverseseepladar_rear
                  });
                }
              });
            }
            that.realTimeData = realTimeData;

            that.times = message.timestamp;
            that.totalTime = message.workcycle[0]?.totaltime;
          }
        );
      }

      if (Object.keys(that.realTimeData).length === 0) {
        if (this.deviceType == 0) {
          this.realTimeData = {
            load: 0,
            momentratio: 0,
            slide: 0,
            rotation: 0,
            height: 0,
            luffing: 0,
            wind: 0,
            horizontal: 0,
            travel: 0,
            weightAnalog: 0,
            heightAnalog: 0,
            travelAnalog: 0,
            rotationAnalog: 0,
          };
        } else if (this.deviceType == 1) {
          this.realTimeData = {
            load: 0,
            momentratio: 0,
            slide: 0,
            rotation: 0,
            height: 0,
            luffing: 0,
            wind: 0,
            horizontal: 0,
            floor: 0,
            personcount: 0,
            doorFront: 0,
            doorRoof: 0,
            speed: 0,
            travel: 0,
            weightAnalog: 0,
            heightAnalog: 0,
            travelAnalog: 0,
            rotationAnalog: 0,
          };
        } else if (this.deviceType == 3) {
          this.realTimeData = {
            load: 0,
            height: 0,
            // wind: message.generic.wind || 0,
            travel: 0,
            wind: 0,
            load2: 0,
            height2: 0,
            // wind: message.generic.wind || 0,
            wind: 0,
            travel2: 0,
          };
        } else if (this.deviceType == 2) {
          this.realTimeData = {
            load: 0,
            height: 0,
            // wind: message.generic.wind || 0,
            travel: 0,
            wind: 0,
            speed: 0,
            speed2: 0,
            speed3: 0,
          };
          console.log("this.realTimeData", this.realTimeData);
        }
      }
    },
    getStatus() {},
    //订阅在线
    getStatus() {
      let that = this;
      createConnect(
        `F/+/+/+/+/+/+/+/+/${this.title}/Status`,

        function (topic, message) {
          // 在这里处理从 MQTT 收到的消息
          console.log("收到来自", topic);
          console.log("消息xxxxxxxxxxx", message);
          that.statusOnline = message.status[0].status;
        }
      );
    },
    //订阅附近设备
    subscribeVicinityAttribute() {
      let that = this;
      createConnects(
        `F/+/+/+/+/+/+/+/+/${this.title}/Vicinity/Attribute`,

        function (topic, message) {
          // 在这里处理从 MQTT 收到的消息
          // console.log('messages',message);
          that.svgTimes = [];
          // that.statusOnline = message.status[0].status;
          that.svgTimes = message.neighbours;
        }
      );

      if (that.svgTimes.length == 0) {
        this.subscribeVicinityStatus();
        let params = {
          deviceImei: this.title,
          termTopic: "attribute",
        };
        screen.getTerm(params).then((res) => {
          let data = JSON.parse(res.data[0]?.termData);

          data.neighbours.map((item) => {
            this.statusArr.map((item2) => {
              if (item.duid == item2.duid) {
                item.status = item2.status;
              }
            });
          });
          that.selfDevice = data.self;
          that.svgTimes = data.neighbours;
        });
      }
    },
    //订阅附近设备状态
    subscribeVicinityStatus() {
      let params = {
        deviceImei: this.title,
        termTopic: "vicinityStatus",
      };
      screen.getTerm(params).then((res) => {
        if (res.data.length > 0) {
          let data = JSON.parse(res.data[0]?.termData);
          this.statusArr = data.neighbours;
        }
      });
    },
    svgClick() {
      this.showDialog = true;
    },
    colseDialog() {
      this.showDialog = false;
    },
    doneDialog() {},
    //订阅工况设置
    subscribeWorkSet() {
      let params = {
        deviceImei: this.title,
        termTopic: "Workset",
      };
      screen.getTerm(params).then((res) => {
        if (res.data.length > 0) {
          let data = JSON.parse(res.data[0]?.termData);

          data.workset.map((item) => {
            if (item.object == "general" && this.deviceType == "0") {
              this.workData = item;
              this.times = data.timestamp;

              this.versioncc = data.version;
            } else if (item.object == "hoist" && this.deviceType == "1") {
              this.workData = item;
              this.times = data.timestamp;
            } else if (item.object == "general" && this.deviceType == "2") {
              this.workData = item;
              this.times = data.timestamp;
            }
          });
        }
      });
    },
    handleClick(tab, event) {
      screen.getListCamera({ deviceSn: this.title }).then((res) => {
        res.data.map((item) => {
          if (item.cameSn == tab.name) {
            this.videoInfo = {
              type: item.cameraType,
              url: tab.name,
            };
          }
        });
        // this.videoList = res.data;
        // this.activeName = res.data[0].cameSn;
        // this.videoInfo = {
        //   type: res.data[0].cameraType,
        //   url: res.data[0].cameSn,
        // };
      });
    },
    getVideoList(deviceSn, type) {
      screen.getListCamera({ deviceSn: deviceSn }).then((res) => {
        this.videoList = res.data;
        this.activeName = res.data[0].cameSn;
        this.videoInfo = {
          type: res.data[0].cameraType,
          url: res.data[0].cameSn,
        };
      });
    },

    getDeviceParam(deviceId) {
      let params = {
        deviceId: deviceId,
        paramLable: "workset",
      };
      screen
        .deviceParam(params)
        .then((res) => {
          let jsonData = JSON.parse(res.data.paramContent);

          this.workData = jsonData.workset;
        })
        .catch((err) => {});
    },
    updateListByType(data, type) {
      data.map((item) => {
        if (item.alarmLevel >= 12) {
          this.list.find((item2) => item2.type === type).show = true;
        }
      });
    },
    getSelectDeviceAlarm(deviceImei) {
      const typeMap = {
        amplitude: "amplitude",
        weight: "weight",
        moment: "moment",
        anticollision: "anticollision",
        barrier: "barrier",
        elevation: "elevation",
        height: "height",
        rotate: "rotate",
        walk: "walk",
        wind: "wind",
        // Add more types as needed
      };
      screen.selectDeviceAlarm({ deviceImei: deviceImei }).then((res) => {
        console.log("resxxxxxxxx", res);
        for (const key in typeMap) {
          if (res.data[key]) {
            this.updateListByType(res.data[key], typeMap[key]);
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-image: url("../../../assets/images/screen/newbgdel.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  background-position: center;
  padding: 0 20px;
  position: relative;
  overflow: auto;
}
.heard {
  height: 6%;
  // border: 1px solid red;
}
.main {
  height: 94%;
  // border: 1px solid red;
  display: flex;
}
.leftMain {
  width: 79%;
  height: 100%;
  margin-right: 1%;
  // border: 1px solid red;
  .leftTop {
    height: 20%;
    // border: 1px solid red;
    .realBox {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .leftModel {
    height: 50%;
    // border: 1px solid red;
    display: flex;
    .leftdel {
      width: 49%;
      height: 100%;
      overflow: auto;
      // border: 1px solid red;
      overflow: auto;
      .simulationBg {
        background-image: url("../../../assets/images/screen/newTitleBox.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 8%;

        background-position: center;
        color: #ffffff;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding-right: 20px;
      }
      .henxian {
        height: 6%;
        color: #ffffff;
        // border: 1px solid red;
        .box8 {
          display: flex;
          justify-content: space-between;
          position: relative;
          // align-items: end;
          height: 94%;
          .box9 {
            display: flex;
            .title {
              margin-left: 10px;
            }
          }
          .box10 {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .henxianbox {
          background-image: url("../../../assets/images/screen/hengxian.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 6%;
          // border: 1px solid red;
        }
      }
      .simulatedBox {
        height: 74%;
        overflow: auto;
      }
      .warnBoxsc {
        height: 20%;
        // border: 1px solid red;
        background-image: url("../../../assets/images/screen/userInfo.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        overflow: hidden;
        display: flex;
        padding: 0 20px;
        align-items: center;
        flex-wrap: wrap;
        .singular {
          width: 16%;
        }
        .showBox {
          color: #ffffff;
        }
        .showBox2 {
          color: red;
        }
        .test {
          display: flex;
          // border: 1px solid red;
        }
      }
    }
    .leftdel2 {
      width: 49%;
      height: 100%;
      margin-left: 2%;
      // border: 1px solid red;
      .videoTitleBox {
        background-image: url("../../../assets/images/screen/newTitleBox.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 8%;
        // margin-bottom: 3%;
        background-position: center;
        color: #ffffff;
        padding-left: 20px;
      }
      .henxian {
        height: 6%;
        color: #ffffff;
        // border: 1px solid red;
        .box8 {
          display: flex;
          justify-content: space-between;
          position: relative;
          // align-items: end;
          height: 94%;
          .box9 {
            display: flex;
            .title {
              margin-left: 10px;
            }
          }
          .box10 {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .henxianbox {
          background-image: url("../../../assets/images/screen/hengxian.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 6%;
          // border: 1px solid red;
        }
      }
      .tabs {
        height: 10%;
      }
      .videoss {
        // margin-top: 3%;
        height: 82%;
        // padding: 16px;
        overflow: auto;
      }
    }
  }
  .leftBottom {
    height: 30%;
    // border: 1px solid yellow;
    display: flex;
    .leftBottomdel {
      width: 49%;
      height: 100%;
      .simulationBg {
        background-image: url("../../../assets/images/screen/newTitleBox.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 14%;

        background-position: center;
        color: #ffffff;
        padding-left: 20px;
      }
      .henxian {
        height: 12%;
        color: #ffffff;
        // border: 1px solid red;
        .box8 {
          display: flex;
          justify-content: space-between;
          position: relative;
          // align-items: end;
          height: 94%;
          .box9 {
            display: flex;
            .title {
              margin-left: 10px;
            }
          }
          .box10 {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .henxianbox {
          background-image: url("../../../assets/images/screen/hengxian.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 6%;
          // border: 1px solid red;
        }
      }
      // border: 1px solid red;
      .realDatadetailbox {
        height: 88%;
      }
    }
    .leftBottomdel2 {
      width: 49%;
      margin-left: 2%;
      height: 100%;
      // border: 1px solid red;
      .simulationBg {
        background-image: url("../../../assets/images/screen/newTitleBox.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;

        background-position: center;
        color: #ffffff;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding-right: 20px;
      }
      .henxian {
        height: 12%;
        color: #ffffff;
        // border: 1px solid red;
        .box8 {
          display: flex;
          justify-content: space-between;
          position: relative;
          // align-items: end;
          height: 94%;
          .box9 {
            display: flex;
            .title {
              margin-left: 10px;
            }
          }
          .box10 {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .henxianbox {
          background-image: url("../../../assets/images/screen/hengxian.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 6%;
          // border: 1px solid red;
        }
      }
      .workSetData {
        height: 88%;
        // margin-top: 10%;
      }
    }
  }
}
.rightMain {
  // margin-left: 2%;
  width: 19%;
  height: 100%;
  // border: 1px solid red;
  .rightTop {
    height: 40%;
    // border: 1px solid red;
  }
  .rightBottom {
    height: 60%;
    // border: 1px solid red;
    .simulationBg {
      background-image: url("../../../assets/images/screen/newTitleBox.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 100%;
      // width: 140%;
      background-position: center;
      color: #ffffff;
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      padding-right: 20px;
    }
    .henxian {
      height: 5%;
      color: #ffffff;
      // border: 1px solid red;
      .box8 {
        display: flex;
        justify-content: space-between;
        position: relative;
        // align-items: end;
        height: 94%;
        .box9 {
          display: flex;
          .title {
            margin-left: 10px;
          }
        }
        .box10 {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .henxianbox {
        background-image: url("../../../assets/images/screen/hengxian.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 6%;
        // border: 1px solid red;
      }
    }
    .warndetail {
      height: 95%;
    }
  }
}
/deep/.el-tabs__item {
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  position: relative;
}
/deep/.el-tabs__item.is-active {
  color: #409eff; /* Default text color for unselected tabs */
}
</style>
